<template>
  <div>
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="d-flex justify-content-end mb-2">
        <b-col>
          <div v-if="isEmpty(viewName)">
            <b-skeleton
              animation="fade"
              width="85%"
            />
            <b-skeleton
              animation="fade"
              width="55%"
            />
          </div>
          <h1>{{ viewName }}</h1>
          <h5 class="text-primary">
            {{ viewDetail }}
          </h5>
        </b-col>
        <b-col class="d-flex justify-content-end align-items-center">
          <div class="align-items-center d-flex">
            <div v-if="interactivities">
              <b-button
                variant="primary"
                @click="interactivitiesScreen"
              >
                {{ $t('interactivities.title') }}
              </b-button>
            </div>
            <div
              v-if="notifications"
              class="align-items-center d-flex"
            >
              <div v-if="!sended">
                <b-button
                  variant="outline-warning"
                  disabled
                  class="d-flex align-items-center"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    class="text-warning mr-1"
                    size="20"
                  />
                  <span class="text-white">
                    {{ $t('notSend') }}
                  </span>
                </b-button>
              </div>
              <div
                v-if="!editNotification"
                class="ml-2"
              >
                <b-dropdown
                  right
                  variant="primary"
                  class="mr-1"
                  :text="$t('Reenviar')"
                >
                  <b-dropdown-item @click.stop.prevent="resendNow">
                    {{ $t('reenviarAhora') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click.stop.prevent="resendCron">
                    {{ $t('ReenviarProg') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>

            <div class="ml-2">
              <b-button
                variant="danger"
                @click="goBack()"
              >
                {{ $t("dataGeneric.goBack") }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-end mb-5 ">
        <b-col class="ml-2">
          <div v-if="isEmpty(name)">
            <b-skeleton
              animation="fade"
              width="50%"
            />
          </div>
          <h4
            v-if="noName"
            class="text-muted"
          >
            {{ name }}
          </h4>
          <h3 v-else>
            {{ name }}
          </h3>
        </b-col>

        <b-col class="d-flex justify-content-end align-items-center">
          <div class="align-items-center d-flex">
            <div v-if="notifications">
              <b-col
                v-if="sendType == 'DRAFT'"
                style="text-align: end;"
              >
                <feather-icon
                  v-b-tooltip.hover.top.v-secondary="$t('deleteNot')"
                  class="mr-1 text-danger cursor-pointer"
                  icon="Trash2Icon"
                  size="30"
                  @click="deleteNotification"
                />
              </b-col>
              <b-col
                v-if="sendType != 'DRAFT'"
                style="text-align: end;"
              >
                <small
                  v-if="originalDate != null"
                  class="text-muted"
                >{{ $t('dateSend') }}</small>
                <h4>
                  <strong>
                    {{ getFormatDate(originalDate) }}
                  </strong>
                </h4>
              </b-col>
            </div>
            <div
              v-if="typeCategory == 'POD'"
              class="d-flex justify-content-end align-items-center"
            >
              <b-button
                v-clipboard:copy="feedPodcast"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                v-b-tooltip.hover.top.v-primary="$t('feedRssPodcast')"
                variant="primary"
                class="align-items-center"
              >
                <feather-icon
                  icon="MicIcon"
                  size="16"
                />
                Feed Url
              </b-button>
            </div>

            <div
              v-if="state"
              class="d-flex justify-content-end align-items-center ml-2"
            >
              <b-badge
                :class="state == 'GRE'
                  ? 'badge-light-success'
                  : state == 'YEW'
                    ? 'badge-light-warning'
                    : 'badge-light-danger'
                "
              >
                <h4
                  :class="state == 'GRE'
                    ? 'text-success'
                    : state == 'YEW'
                      ? 'text-warning'
                      : 'text-danger'
                  "
                  style="text-transform: uppercase; margin-top: 5px;"
                >
                  {{
                    state == "GRE"
                      ? $t('ecommerce.public')
                      : state == "YEW"
                        ? $t('dataGeneric.internal')
                        : $t('ecommerce.NoPublic')
                  }}
                </h4>
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- lazy -->
      <b-tabs
        v-if="languageService"
        v-model="tabIndex"
        lazy
        pills
        vertical
      >
        <b-tab
          v-for="i in locales"
          :key="i.locale"
          class="align-items-start"
          style="align-items: flex-start;"
        >
          <template #title>
            <div class="">
              <b-img
                :src="i.img"
                height="14px"
                width="22px"
                :alt="i.locale"
              />
              <strong class="ml-50">{{ i.name }}</strong>
            </div>
          </template>
          <component
            :is="template"
            ref="childRef"
            :key="i.locale"
            :language="i.locale"
            :base="base"
            :type-file="typeFile"
            @create="getMultilanguageField"
            @return="getName"
          />
        </b-tab>
      </b-tabs>
      <component
        :is="template"
        v-else
        ref="childRef"
        :language="base"
        :base="base"
        :type-file="typeFile"
        @create="getMultilanguageField"
        @return="getName"
      />
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton,
  BTabs,
  BImg, BDropdown, BDropdownItem,
  BSkeleton,
  BRow,
  BOverlay,
  VBTooltip,
  BBadge,
  BCol,
  BTab,
} from 'bootstrap-vue'
import localesLanguages from '@core/utils/languageModels'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { isEmpty, showToast } from '@/store/functions'

export default {
  components: {
    BTabs,
    BOverlay,
    BDropdown,
    BDropdownItem,
    BTab,
    BImg,
    BSkeleton,
    BBadge,
    BRow,
    BCol,
    BButton,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sendType: '',
      overlay: true,
      languages: [],
      languageService: true,
      typeFile: null,
      interactivities: false,
      notifications: false,
      editNotification: true,
      sended: false,
      state: null,
      typeCategory: '',
      noName: false,
      typeContent: '',
      feedPodcast: '',
      showXml: false,
      isEmpty,
      tabIndex: 0,
      name: '',
      base: 'Es',
      viewName: '',
      viewDetail: '',
      categoryName: null,
      userData: getUserData(),
      locales: [],
    }
  },
  mounted() {
    this.getClient()
  },
  methods: {
    getLocaleFormat(languagesString) {
      const idiomas = languagesString.split('|')

      const idiomasFormateados = idiomas.map(idioma => {
        const partes = idioma.split('-') // Dividir el idioma en partes usando '-'
        const idiomaFormateado = partes
          .map(parte => parte.charAt(0).toUpperCase() + parte.slice(1)) // Capitalizar cada parte
          .join('') // Unir las partes sin espacio
        return idiomaFormateado
      })
      return idiomasFormateados
    },
    resendCron() {
      if (!isEmpty(this.$refs.childRef[0])) {
        this.$refs.childRef[0].resendCron()
      } else this.$refs.childRef.resendCron()
    },
    deleteNotification() {
      if (!isEmpty(this.$refs.childRef[0])) {
        this.$refs.childRef[0].deleteNotification()
      } else this.$refs.childRef.deleteNotification()
    },
    resendNow() {
      if (!isEmpty(this.$refs.childRef[0])) {
        this.$refs.childRef[0].resendNow()
      } else this.$refs.childRef.resendNow()
    },
    getClient() {
      axios
        .post('', {
          query: `
              query{
              client(id:"${this.userData.profile.client.id}"){
                  externalPayMethod
                  currency
                  defaultLanguage
                  availableLanguages
              }
          }
          `,
        })
        .then(res => {
          const { availableLanguages } = res.data.data.client

          const arrayLanguages = this.getLocaleFormat(availableLanguages)

          this.languageService = arrayLanguages.length > 1
          this.base = `${this.getLocaleFormat(res.data.data.client.defaultLanguage)}`
          const languages = localesLanguages.filter(locale => arrayLanguages.includes(locale.locale))

          const baseObject = languages.find(obj => obj.locale === this.base)
          const filteredArray = languages.filter(obj => obj.locale !== this.base)
          this.locales = [baseObject, ...filteredArray]
        })
        .catch(() => {
        })
    },
    interactivitiesScreen() {
      this.$router.push({
        name: 'interactivities-video',
        params: { id: this.$route.params.id },
      })
    },
    onCopy() {
      showToast(this.$t('code.tCopy'), 1, this)
    },
    onError() {
      showToast(this.$t('code.errorCopy'), 2, this)
    },
    getFormatDate(date) {
      if (date !== null) {
        const dateDB = new Date(date)
        const year = dateDB.getFullYear()
        const month = String(dateDB.getMonth() + 1).padStart(2, '0')
        const day = String(dateDB.getDate()).padStart(2, '0')
        const hours = String(dateDB.getHours()).padStart(2, '0')
        const minutes = String(dateDB.getMinutes()).padStart(2, '0')
        const seconds = String(dateDB.getSeconds()).padStart(2, '0')
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
        return formattedDate
      }
      return ''
    },
    generateXml(idCategory) {
      const mode = process.env.VUE_APP_MODE ? process.env.VUE_APP_MODE : process.env.NODE_ENV
      let host = ''
      switch (mode) {
        case 'develop':
          host = 'https://develop.interactvty.com'
          break
        case 'sandbox':
          host = 'https://sandbox.interactvty.com'
          break
        case 'production':
          host = 'https://api.interactvty.com'
          break
        default:
          host = 'http://localhost:8000'
          break
      }

      const clientName = this.userData.profile.client.name
      this.feedPodcast = `${host}/api/2.0/contents/feed_podcasts/?category_id=${idCategory}&client=${clientName}`
    },
    getMultilanguageField() {
      const childComponents = this.$refs.childRef
      if (!isEmpty(this.$refs.childRef[0])) {
        this.$refs.childRef[0].formSubmitted(childComponents)
      } else this.$refs.childRef.formSubmitted([childComponents])
    },
    getName(data) {
      this.overlay = false
      const {
        name, viewName, viewDetail, sendType, typeFile, originalDate, interactivities, editNotification, notifications, sended, typeCategory, idCategory,
      } = data
      if (name) {
        this.name = name
        this.noName = false
      } else {
        this.name = this.$t('noTranslate')
        this.noName = true
      }

      this.viewName = viewName
      this.viewDetail = viewDetail
      if (typeFile) {
        this.typeFile = typeFile
      } else {
        this.typeFile = null
      }
      if (typeCategory) {
        this.typeCategory = typeCategory
        this.generateXml(idCategory)
      } else {
        this.typeCategory = ''
      }
      this.notifications = notifications
      if (this.notifications) {
        this.sended = sended
        this.originalDate = originalDate
        this.sendType = sendType
        this.editNotification = editNotification
      }
      try {
        this.interactivities = interactivities
        this.state = data.state
      } catch (error) {
        this.interactivities = false
        this.state = null
      }
    },
    goBack() {
      window.history.back()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "vue-context/src/sass/vue-context";

.nav-pills {
  align-items: flex-start;
}
</style>
